/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RoomDto } from 'models/dtos/RoomDto';

import { ApiContext } from 'Context';
import React from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useAuthContext } from 'components/common/context/AuthContext';
import { AlarmDto } from 'models/dtos/AlarmDto';
import { MonitoringDto } from 'models/dtos/MonitoringDto';

function useMeasurement(room?: RoomDto[]) {
    const { token } = useAuthContext();
    const client = React.useContext(ApiContext);
    return useQuery(
        ['measurements'],
        () => client.getMeasurement(token, room),
        {
            enabled: !!room && !!room.length,
            refetchInterval: 10000,
        }
    );
}

export const useRoomStatus = () => {
    const { token } = useAuthContext();
    const client = React.useContext(ApiContext);
    return useQuery(['rooms'], () => client.getRoomStatus(token), {});
};

function useGetAlarm() {
    const { token } = useAuthContext();
    const client = React.useContext(ApiContext);
    return useQuery(['alarm'], () => client.getAlarm(token), {
        refetchInterval: 10000,
    });
}

function useOneRoom(roomId?: string) {
    const { token } = useAuthContext();
    const client = React.useContext(ApiContext);
    return useQuery(
        ['room', { roomId }],
        () => client.getOneRoom(token, roomId),
        {
            enabled: !!roomId,
        }
    );
}

function useSleepOverview(
    startDate: string,
    resultCount: number,
    sensorIdMainRoom?: string,
    sensorIdBathRoom?: string
) {
    const { token } = useAuthContext();
    const client = React.useContext(ApiContext);
    const queryKey = [
        'SleepOverview',
        startDate,
        resultCount,
        sensorIdMainRoom,
        sensorIdBathRoom,
    ];
    return useQuery(
        queryKey,
        () =>
            client.getSleepOverview(
                token,
                startDate,
                resultCount,
                sensorIdMainRoom,
                sensorIdBathRoom
            ),
        { enabled: !!sensorIdBathRoom && !!sensorIdMainRoom }
    );
}

function useSleepDuration(
    startDate: string,
    sensorIdBathRoom?: string,
    sensorIdMainRoom?: string,
    isDetailed?: boolean
) {
    const { token } = useAuthContext();
    const client = React.useContext(ApiContext);
    const queryKey = [
        'SleepDuration',
        startDate,
        sensorIdBathRoom,
        sensorIdMainRoom,
        isDetailed,
    ];
    return useQuery(
        queryKey,
        () =>
            client.getSleepStageDuration(
                token,
                startDate,
                sensorIdBathRoom,
                sensorIdMainRoom,
                isDetailed
            ),
        { enabled: !!sensorIdBathRoom && !!sensorIdMainRoom }
    );
}
function useSleepDistribution(
    startDate: string,
    sensorIdBathRoom?: string,
    sensorIdMainRoom?: string
) {
    const { token } = useAuthContext();
    const client = React.useContext(ApiContext);
    const queryKey = [
        'SleepDistribution',
        startDate,
        sensorIdBathRoom,
        sensorIdMainRoom,
    ];
    return useQuery(
        queryKey,
        () =>
            client.getSleepDistribution(
                token,
                startDate,
                sensorIdBathRoom,
                sensorIdMainRoom
            ),
        { enabled: !!sensorIdBathRoom && !!sensorIdMainRoom }
    );
}

function useSleepReport(
    startDate: string,
    sensorIdBathRoom?: string,
    sensorIdMainRoom?: string
) {
    const { token } = useAuthContext();
    const client = React.useContext(ApiContext);
    const queryKey = [
        'SleepReport',
        startDate,
        sensorIdBathRoom,
        sensorIdMainRoom,
    ];
    return useQuery(
        queryKey,
        () =>
            client.getSleepReport(
                token,
                startDate,
                sensorIdBathRoom,
                sensorIdMainRoom
            ),
        { enabled: !!sensorIdBathRoom && !!sensorIdMainRoom }
    );
}

function useMeasurementValues(
    type: string,
    startDate: string,
    resultCount: number,
    sensorIdBathRoom?: string,
    sensorIdMainRoom?: string
) {
    const { token } = useAuthContext();
    const client = React.useContext(ApiContext);
    const queryKey = [
        'measurementValues',
        type,
        startDate,
        resultCount,
        sensorIdBathRoom,
        sensorIdMainRoom,
    ];
    return useQuery(
        queryKey,
        () =>
            client.getMeasurementValues(
                token,
                type,
                startDate,
                resultCount,
                sensorIdBathRoom,
                sensorIdMainRoom
            ),
        { enabled: !!sensorIdBathRoom && !!sensorIdMainRoom }
    );
}

export const useCancelAlarm = (userId: string) => {
    const { token } = useAuthContext();
    const client = React.useContext(ApiContext);
    const queryClient = useQueryClient();
    return useMutation(
        ['cancelAlarm'],
        (alarm: AlarmDto) => client.cancelAlarm(token, alarm.alarmId, userId),
        {
            onSuccess: async (data) => {
                queryClient.refetchQueries(['alarm']);
                return data;
            },
            onError: async (error) => {
                return error;
            },
        }
    );
};

export const useSaveUpdateMonitoring = () => {
    const { token } = useAuthContext();
    const client = React.useContext(ApiContext);
    const queryClient = useQueryClient();
    return useMutation(
        ['setMonitoring'],
        (monitoring: MonitoringDto) =>
            client.saveUpdateMonitoring(token, monitoring),
        {
            onSuccess: async (data) => {
                queryClient.invalidateQueries(['rooms']);
                return data;
            },
            onError: async (error) => {
                return error;
            },
        }
    );
};

export {
    useMeasurement,
    useOneRoom,
    useMeasurementValues,
    useSleepOverview,
    useGetAlarm,
    useSleepDuration,
    useSleepReport,
    useSleepDistribution,
};
