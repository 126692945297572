import * as React from 'react';
import { Box, Flex, Grid, useDisclosure, Text } from '@chakra-ui/react';
import { RoomOff } from 'models/views/RoomViewModel';
import { Button } from 'components/common/buttons/Button';
import { StartMonitoringModal } from 'components/common/modals/StartMonitoringModal';
import { ButtonVariant } from 'models/dtos/ButtonModel';
import { AlarmModal } from 'components/common/modals/AlarmModal';
import { useAuth } from 'react-oidc-context';
import { FaRegBell } from 'react-icons/fa';
import { useCancelAlarm } from 'api/Hooks';

export const RoomListMonitoringOffItem: React.FC<RoomOff> = ({
    id,
    name = '',
    alarm,
    setRefreshBlocker,
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const auth = useAuth();
    const userId = auth.user?.profile.username as string;

    const { mutate: cancelAlarm } = useCancelAlarm(userId);

    const handleUpdateAlarm = () => {
        cancelAlarm(alarm!);
        setIsModalOpen(false);
    };
    const wrappedOnOpen = () => {
        if (setRefreshBlocker) {
            setRefreshBlocker(true);
        }
        onOpen();
    };
    const wrappedOnClose = () => {
        if (setRefreshBlocker) {
            setRefreshBlocker(false);
        }
        onClose();
    };

    return (
        <Grid
            backgroundColor={alarm?.active ? 'staySafe.rustOrange' : ''}
            onClick={
                alarm
                    ? () => {
                          setIsModalOpen(true);
                      }
                    : () => setIsModalOpen(false)
            }
            templateColumns='auto repeat(16, 100px) auto'
            templateRows='32px 32px'
            rowGap='32px'
            alignItems='baseline'
        >
            <Box
                gridColumn={2}
                gridColumnStart={2}
                gridColumnEnd={10}
                textStyle='h2'
            >
                {name}
            </Box>
            <Box gridRow={2} gridColumnStart={2} gridColumnEnd={4}>
                {alarm?.active ? (
                    <Flex gap='8px'>
                        <Text>Hærverksalarm</Text>
                        <FaRegBell />
                    </Flex>
                ) : (
                    <></>
                )}
            </Box>
            <Box
                gridColumnStart={13}
                gridColumnEnd={16}
                justifyContent='end'
                display='flex'
                fontSize='18px'
                alignItems='baseline'
            >
                <Button
                    text='Start monitorering'
                    variant={ButtonVariant.primary}
                    onClickCallback={() => {
                        wrappedOnOpen();
                    }}
                />
            </Box>
            <Box
                gridColumnStart={2}
                gridColumnEnd={16}
                borderTop='1px'
                borderColor='staySafe.darkGrey'
            />

            <StartMonitoringModal
                isOpen={isOpen}
                onOpen={wrappedOnOpen}
                roomId={id}
                onCloseCallback={() => {
                    wrappedOnClose();
                }}
                setRefreshBlocker={setRefreshBlocker}
            />
            <AlarmModal
                show={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleUpdateAlarm}
                heading='Kvitter alarm for Test'
                alarm={alarm}
                user={`${auth.user?.profile.given_name} ${auth.user?.profile.family_name}`}
            />
        </Grid>
    );
};
