declare global {
    interface Window {
        // eslint-disable-next-line
        env: any;
    }
}

// change with your own variables
type EnvType = {
    REACT_APP_API_URL: string;
    REACT_APP_WARD_ID: string;
    REACT_APP_API_KEY: string;
    REACT_APP_OIDC_AUTHORITY: string;
    REACT_APP_OIDC_CLIENTID: string;
    REACT_APP_OIDC_REDIRECT_URL: string;
};
export const env: EnvType = { ...process.env, ...window.env };
