import * as React from 'react';
import { Box } from '@chakra-ui/react';

export interface SleepstateItemViewModel {
    text: string;
    icon: React.ReactNode;
}

export const SleepstateItem: React.FC<SleepstateItemViewModel> = ({
    text,
    icon,
}) => {
    return (
        <Box
            justifyContent='start'
            display='flex'
            fontSize='18px'
            alignItems='baseline'
        >
            <Box paddingRight='8px' alignSelf='normal'>
                {icon}
            </Box>
            {text}
        </Box>
    );
};
