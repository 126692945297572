import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
    fonts: {
        heading: 'Open Sans',
        body: 'Open Sans',
    },
    textStyles: {
        h1: {
            fontSize: '48px',
            fontWeight: 'normal',
            color: 'staySafe.darkGrey',
        },
        h2: {
            fontSize: '32px',
            fontWeight: 'bold',
            color: 'staySafe.darkGrey',
        },
        h3: {
            fontSize: '22px',
            fontWeight: 'bold',
            color: 'staySafe.darkGrey',
        },
        h4: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'staySafe.darkGrey',
        },
    },
    colors: {
        staySafe: {
            darkGrey: '#4A4A4A',
            peach: '#FFE2AC',
            lightPeach: '#FEF6E5',
            mint: '#BFE1D9',
            lightMint: '#E6F2EE',
            sand: '#F5F5F3',
            blue: '#004B93',
            lightBlue: '#7AB2E1',
            rust: '#BFE1D9',
            darkRust: '#C3861B',
            red: '#CC0000',
            white: '#FFFFFF',
            black: '#1F2426',
            pink: '#F95E4E',
            rustOrange: '#D9951E',
        },
    },
    components: {
        Button: {
            variants: {
                'primary': {
                    background: 'staySafe.blue',
                    color: 'staySafe.white',
                    fontSize: '16px',
                    borderRadius: '0',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    _hover: {
                        color: 'staySafe.white',
                        background: 'staySafe.lightBlue',
                    },
                    _focus: {
                        color: 'staySafe.white',
                        background: 'staySafe.lightBlue',
                        border: '2px',
                        borderStyle: 'solid',
                        boxShadow: 'unset',
                        borderColor: 'staySafe.blue',
                        transform: 'translateY(2px)',
                    },
                },
                'secondary': {
                    color: 'staySafe.blue',
                    background: 'staySafe.white',
                    fontSize: '16px',
                    borderRadius: '0',
                    border: '2px',
                    borderStyle: 'solid',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderColor: 'staySafe.blue',
                    _hover: {
                        color: 'staySafe.white',
                        background: 'staySafe.lightBlue',
                    },
                    _focus: {
                        color: 'staySafe.white',
                        background: 'staySafe.lightBlue',
                        border: '2px',
                        borderStyle: 'solid',
                        boxShadow: 'unset',
                        borderColor: 'staySafe.blue',
                        transform: 'translateY(2px)',
                    },
                },
                'text': {
                    color: 'staySafe.blue',
                    fontSize: '16px',
                    borderRadius: '0',
                    _hover: {
                        color: 'staySafe.white',
                        background: 'staySafe.lightBlue',
                    },
                    _focus: {
                        color: 'staySafe.white',
                        background: 'staySafe.lightBlue',
                        boxShadow: 'unset',
                    },
                },
                'disabled': {
                    background: 'rgba(0, 0, 0, 0.2)',
                    color: 'staySafe.white',
                    fontSize: '16px',
                    borderRadius: '0',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                },
            },
        },
        Select: {
            variants: {
                'outline': {
                    field: {
                        bg: 'staySafe.white',
                    },
                },
            },
        },
        Input: {
            variants: {
                'outline': {
                    field: {
                        bg: 'staySafe.white',
                    },
                },
            },
        },
        Textarea: {
            variants: {
                'outline': {
                    bg: 'staySafe.white',
                },
            },
        },
    },
});
