import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
    ObservationBVCDto,
    ObservationViewDto,
} from 'models/dtos/ObservationDto';
import axios, { AxiosResponse } from 'axios';
import { env } from 'env';
import { useAuthContext } from '../context/AuthContext';

export const BVCChart: React.FC<ObservationViewDto> = ({
    roomId,
    startDate,
}) => {
    const [BVC, setBVC] = React.useState<ObservationBVCDto[]>([]);
    const { token } = useAuthContext();

    React.useEffect(() => {
        function getObservationValues() {
            const endDate = JSON.stringify(new Date(Date.now())).replace(
                /"/g,
                ''
            );
            axios
                .get(
                    `${env.REACT_APP_API_URL}/observations?roomId=${roomId}&start=${startDate}&end=${endDate}&observationType=BVC`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((response: AxiosResponse) => {
                    setBVC(response.data);
                });
        }
        getObservationValues();
    }, [roomId, startDate, token]);

    const series1 = BVC?.sort((a, b) =>
        a.timeStamp > b.timeStamp ? 1 : -1
    ).map((entry) => {
        const parsedDate: number = new Date(
            entry.timeStamp.toString()
        ).getTime();
        const result = {
            x: parsedDate,
            y: entry.total,
        };
        return result;
    });

    const options = {
        chart: {
            type: 'column',
        },
        title: '',

        xAxis: {
            type: 'datetime',
        },

        yAxis: {
            title: 'BVC Totalsum',
        },
        series: [
            {
                dataLabels: {
                    enabled: false,
                    format: '{point.y:.0f}',
                },
                data: series1.map((point) => {
                    return {
                        x: point.x,
                        y: point.y,
                        color:
                            // eslint-disable-next-line no-nested-ternary
                            point.y === 0
                                ? 'white'
                                : point.y < 4
                                ? 'orange'
                                : 'red',
                    };
                }),
                colorByPoint: false,
            },
        ],

        credits: {
            enabled: false,
        },
        time: {
            useUTC: false,
        },
    };
    return <HighchartsReact highcharts={Highcharts} options={options} />;
};
