import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function OidcRedirect() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/');
    }, [navigate]); // Empty dependency array to run only once on initial render

    // eslint-disable-next-line react/react-in-jsx-scope
    return null; // Instead of an empty fragment, return null for clarity
}

export default OidcRedirect;
