import React from 'react';
import { HStack, Box, Text } from '@chakra-ui/react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TableExplanation = () => {
    return (
        <>
            <Text>
                Dra i scrollbaren for å se ønsket del av grafen, eller klikk på
                ønsket zoom-nivå.
            </Text>
            <HStack spacing={8} marginBottom={4}>
                <HStack>
                    <Box width='24px' height='24px' backgroundColor='white' />
                    <Text>0 (ingen tiltak)</Text>
                </HStack>
                <HStack>
                    <Box
                        width='24px'
                        height='24px'
                        backgroundColor='staySafe.peach'
                    />
                    <Text>2-3 (vurdere tiltak)</Text>
                </HStack>
                <HStack>
                    <Box
                        width='24px'
                        height='24px'
                        backgroundColor='staySafe.pink'
                    />
                    <Text>4-6 (iverksette tiltak)</Text>
                </HStack>
            </HStack>
        </>
    );
};
