import React from 'react';
import { Grid, Box } from '@chakra-ui/react';
import { RoomChart } from 'components/common/charts/RoomChart';
import { RoomDto } from 'models/dtos/RoomDto';

interface propType {
    roomId: string | undefined;
    data: RoomDto | undefined;
    startDate: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function RoomChartView(props: propType) {
    const { roomId, data, startDate } = props;

    return (
        <Grid
            gridColumn={3}
            gap='16px'
            templateColumns='repeat(auto-fit, minmax(500px, 1fr))'
        >
            <Box bg='white' marginTop='16px' padding='16px' maxW='570px'>
                <RoomChart
                    roomId={roomId}
                    type='MOVEMENT'
                    chartTitle='Bevegelse'
                    yAxisUnit='i prosent'
                    yAxisMax={100}
                    sensorIdMainRoom={data?.sensorIdMainRoom}
                    sensorIdBathRoom={data?.sensorIdBathRoom}
                    startDate={startDate}
                />
            </Box>
            <Box bg='white' marginTop='16px' padding='16px' maxW='570px'>
                <RoomChart
                    roomId={roomId}
                    type='BREATHINGRATE'
                    chartTitle='Respirasjon'
                    yAxisUnit='pust/min.'
                    sensorIdMainRoom={data?.sensorIdMainRoom}
                    sensorIdBathRoom={data?.sensorIdBathRoom}
                    startDate={startDate}
                />
            </Box>
            {/* <Box bg='white' marginTop='16px' padding='16px' maxW='570px'>
                <RoomChart
                    roomId={roomId}
                    type='HEARTRATE'
                    chartTitle='Pulsfrekvens'
                    yAxisUnit='slag/min.'
                    sensorIdMainRoom={data?.sensorIdMainRoom}
                    sensorIdBathRoom={data?.sensorIdBathRoom}
                    startDate={startDate}
                />
            </Box> */}
        </Grid>
    );
}

export { RoomChartView };
