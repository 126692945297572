import { useToast, Text, Alert, AlertIcon, Box } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthContextProvider } from 'components/common/context/AuthContext';
import * as React from 'react';
import axios from 'axios';
import { useAuth } from 'react-oidc-context';
import bffApi from './api/bffApiIndex';

export const ApiContext = React.createContext(bffApi);

const QueryClientProviderContext: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const toast = useToast();
    const auth = useAuth();

    const queryClient = React.useMemo(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnMount: false,
                        refetchOnWindowFocus: false,
                        retryOnMount: false,
                        retry: false,
                        staleTime: 3600000, // 1 hour
                        cacheTime: 1800000, // 30 minutes
                        keepPreviousData: true,
                        onError(err) {
                            toast({
                                render: () => (
                                    <Alert status='error' borderRadius={4}>
                                        <AlertIcon boxSize='12px' mr={1} />
                                        <Box flex='1'>
                                            <Text fontSize='md'>
                                                Noe gikk galt!
                                            </Text>
                                            <Text fontSize='sm'>
                                                {(err as Error)?.message}
                                            </Text>
                                        </Box>
                                    </Alert>
                                ),

                                duration: 5000,
                                isClosable: true,
                                position: 'top',
                            });
                        },
                    },
                },
            }),
        [toast]
    );

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error?.response?.status === 401) {
                auth.signinRedirect();
            }

            return Promise.reject(error);
        }
    );

    return (
        <QueryClientProvider client={queryClient}>
            {children}
            <ReactQueryDevtools />
        </QueryClientProvider>
    );
};

export const AppContextProvider: React.FC<{
    // eslint-disable-next-line react/require-default-props
    api?: typeof bffApi;
    children: React.ReactNode;
}> = ({ api = bffApi, children }) => {
    return (
        <QueryClientProviderContext>
            <AuthContextProvider>
                <ApiContext.Provider value={api}>
                    {children}
                </ApiContext.Provider>
            </AuthContextProvider>
        </QueryClientProviderContext>
    );
};
