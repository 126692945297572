import * as React from 'react';
import { RiHotelBedLine } from 'react-icons/ri';
import { MdAccessibility, MdOutlinePersonOff } from 'react-icons/md';
import { Box } from '@chakra-ui/react';
import {
    PresenceSleepstateType,
    SleepstateViewModel,
} from 'models/types/SleepStateType';
import { SleepstateItem } from './SleepstateItem';

export const Sleepstate: React.FC<SleepstateViewModel> = ({
    presenceSleepstate,
}) => {
    let text: string;
    let icon: React.ReactNode;
    switch (presenceSleepstate) {
        case PresenceSleepstateType.awake:
            text = 'Våken';
            icon = <MdAccessibility size='24px' />;
            break;
        case PresenceSleepstateType.asleep:
            text = 'Sover';
            icon = <RiHotelBedLine size='24px' />;
            break;
        default:
            text = 'Ikke tilstede';
            icon = <MdOutlinePersonOff size='24px' />;
    }

    return (
        <Box display='flex' fontSize='18px' alignItems='baseline'>
            <SleepstateItem text={text} icon={icon} />
        </Box>
    );
};
