export interface ButtonViewModel {
    text: string;
    type?: ButtonType;
    variant?: ButtonVariant;
    onClickCallback?: () => void;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    style?: {
        position: string;
        top: string;
        left: string;
        width: string;
        height: string;
        backgroundColor: string;
        zIndex: number;
        cursor: string;
    };
}

export enum ButtonType {
    'button' = 'button',
    'submit' = 'submit',
    'reset' = 'reset',
}

export enum ButtonVariant {
    'primary' = 'primary',
    'secondary' = 'secondary',
    'text' = 'text',
    'disabled' = 'disabled',
    'link' = 'link',
}
