/* eslint-disable no-nested-ternary */
import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSleepDistribution } from 'api/Hooks';
import { RoomChartViewModel } from 'models/dtos/RoomChartView';

export const SleepStagesChart: React.FC<RoomChartViewModel> = ({
    chartTitle,
    sensorIdMainRoom,
    sensorIdBathRoom,
    startDate,
}) => {
    const { data: sleepDuration } = useSleepDistribution(
        startDate,
        sensorIdMainRoom,
        sensorIdBathRoom
    );

    const series1 =
        sleepDuration &&
        sleepDuration.map((entry) => {
            const result = {
                y: entry.coverage,
                x: entry.sleepStage,
                color:
                    entry.sleepStage === 0
                        ? '#e74c3c'
                        : entry.sleepStage === 1
                        ? '#3498db'
                        : entry.sleepStage === 2
                        ? '#f1c40f'
                        : entry.sleepStage === 3
                        ? '#2ecc71'
                        : entry.sleepStage === 4
                        ? '#9b59b6'
                        : '#34495e',
                name:
                    entry.sleepStage === 0
                        ? 'Ukjent status'
                        : entry.sleepStage === 1
                        ? 'Ikke tilstede'
                        : entry.sleepStage === 2
                        ? 'Våken'
                        : entry.sleepStage === 3
                        ? 'REM'
                        : entry.sleepStage === 4
                        ? 'Lett'
                        : 'Dyp',
            };
            return result;
        });

    const options = {
        chart: {
            type: 'pie',
        },
        title: {
            text: chartTitle,
        },
        xAxis: {
            type: 'category',
        },
        legend: {
            enabled: false,
        },
        series: [{ data: series1, dataGrouping: {} }],
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};
