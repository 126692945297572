import * as React from 'react';
import {
    Box,
    Grid,
    Radio,
    RadioGroup,
    VStack,
    Stack,
    Text,
    Heading,
    GridItem,
    Center,
    Button as ChakraButton,
    Flex,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { FiArrowLeft, FiWind } from 'react-icons/fi';
import { FaRegBell } from 'react-icons/fa';
import { MdDirectionsWalk } from 'react-icons/md';
import { Sleepstate } from 'components/common/sleepstate/Sleepstate';
import { Measurement } from 'components/common/measurements/Measurement';
import {
    useCancelAlarm,
    useGetAlarm,
    useMeasurement,
    useRoomStatus,
} from 'api/Hooks';
import { RoomDto } from 'models/dtos/RoomDto';
import { AlarmModal } from 'components/common/modals/AlarmModal';
import { AlarmDto } from 'models/dtos/AlarmDto';
import { ButtonVariant } from 'models/dtos/ButtonModel';
import { Button } from 'components/common/buttons/Button';
import { useAuth } from 'react-oidc-context';
import { SleepDetail } from './SleepDetail';
import { BVCDetail } from './BVCDetail';
import { RoomDetail } from './RoomDetail';

export const RoomDetailOverview: React.FC = () => {
    const auth = useAuth();
    const userId = auth.user?.profile.username as string;
    const [isOpen, setIsOpen] = React.useState(false);
    const { roomId } = useParams();
    const { data: alarmData } = useGetAlarm();
    const { data: roomData } = useRoomStatus();

    const filteredRooms = roomData?.filter(
        (room: RoomDto) => room.id === roomId
    );

    const { data: measurementData } = useMeasurement(filteredRooms);

    const navigate = useNavigate();

    const movement = measurementData && measurementData[roomId!]?.movement;

    // const heartRate = measurementData && measurementData[roomId!]?.heartRate;

    const respiration =
        measurementData && measurementData[roomId!]?.breathingRate;

    const presenceStatus =
        measurementData && measurementData[roomId!]?.presenceStatus;
    const alarm = alarmData?.filter((x) =>
        roomData?.some(
            (room) =>
                room.sensorIdMainRoom === x.serialNum ||
                room.sensorIdBathRoom === x.serialNum
        )
    );

    const [value, setValue] = React.useState(
        localStorage.getItem('roomDetailOverviewSelect') || 'Monitorering'
    );
    React.useEffect(() => {
        localStorage.setItem('roomDetailOverviewSelect', value);
    }, [value]);

    const { mutate: cancelAlarm } = useCancelAlarm(userId);

    const handleUpdateAlarm = (alarmCancel: AlarmDto) => {
        cancelAlarm(alarmCancel);
        setIsOpen(false);
    };

    return (
        <>
            {alarm?.map(
                (a, index) =>
                    a.active && (
                        <ChakraButton
                            key={a.serialNum}
                            backgroundColor='staySafe.rustOrange'
                            _hover={{
                                backgroundColor: 'staySafe.DarkGrey',
                            }}
                            position='fixed'
                            top={`${40 + index * 30}%`}
                            left='10%'
                            width='80%'
                            height='25%'
                            zIndex={9999}
                            cursor='pointer'
                            onClick={() => setIsOpen(true)}
                            color='white'
                            size='lg'
                        >
                            <Flex gap='20px'>
                                <FaRegBell size='40px' />
                                <Text fontSize='3xl'>{`Hærverksalarm på rom ${
                                    roomData?.find(
                                        (x) =>
                                            x.sensorIdBathRoom ===
                                                a.serialNum ||
                                            x.sensorIdMainRoom === a.serialNum
                                    )?.name
                                }`}</Text>
                                <FaRegBell size='40px' />
                            </Flex>
                        </ChakraButton>
                    )
            )}

            <Grid
                templateColumns='120px 400px repeat(auto-fit, minmax(320px, 1fr)) 120px'
                bg='staySafe.lightMint'
                minH='calc(100vh - 130px)'
            >
                <Stack
                    gridColumn={2}
                    rowGap='8px'
                    gridTemplateRows='56px 56px repeat(3, 40px) 48px 64px 48px'
                >
                    <Box display='flex' fontSize='18px' alignItems='self-end'>
                        <Button
                            text='Tilbake'
                            variant={ButtonVariant.text}
                            leftIcon={<FiArrowLeft size='24px' />}
                            onClickCallback={() => {
                                navigate('/');
                            }}
                        />
                    </Box>

                    <Box textStyle='h2'>
                        {filteredRooms?.map((name) => {
                            return name.name;
                        })}
                    </Box>

                    <Sleepstate presenceSleepstate={presenceStatus} />

                    <Measurement
                        text='Bevegelse'
                        unit='av 100'
                        icon={<MdDirectionsWalk size='24px' />}
                        value={movement}
                    />

                    <Measurement
                        text='Respirasjon'
                        unit='pust/min.'
                        icon={<FiWind size='24px' />}
                        value={respiration}
                    />
                    {/* <Measurement
                        text='Pulsfrekvens'
                        unit='slag/min.'
                        icon={<FaRegHeart size='24px' />}
                        value={heartRate}
                    /> */}
                </Stack>
                <Box paddingTop='40px' gridColumn={3}>
                    <Center>
                        <Stack isInline>
                            <RadioGroup
                                value={value}
                                onChange={(nextValue: string) =>
                                    setValue(nextValue)
                                }
                            >
                                <VStack align='start'>
                                    <Heading marginBottom='40px'>
                                        {value}
                                    </Heading>
                                    <Text>
                                        Velg hvilken parameter du ønsker å se på
                                    </Text>
                                    <Radio
                                        value='Monitorering'
                                        fontSize='32px'
                                        backgroundColor='white'
                                    >
                                        Monitorering
                                    </Radio>
                                    <Radio
                                        backgroundColor='white'
                                        value='Søvnanalyse'
                                        fontSize='32px'
                                    >
                                        Søvnanalyse
                                    </Radio>
                                    <Radio
                                        backgroundColor='white'
                                        value='Voldsrisiko'
                                        fontSize='32px'
                                    >
                                        Voldsrisiko
                                    </Radio>
                                </VStack>
                            </RadioGroup>
                        </Stack>
                    </Center>
                </Box>
                <GridItem paddingTop='40px' colSpan={4} paddingLeft='120px'>
                    {value === 'Monitorering' && <RoomDetail />}
                    {value === 'Søvnanalyse' && <SleepDetail />}
                    {value === 'Voldsrisiko' && <BVCDetail />}
                </GridItem>
            </Grid>
            <AlarmModal
                show={isOpen}
                onClose={() => setIsOpen(false)}
                onSubmit={handleUpdateAlarm}
                user={`${auth.user?.profile.given_name} ${auth.user?.profile.family_name}`}
                alarm={alarmData?.find((a) =>
                    roomData?.find(
                        (x) =>
                            x.sensorIdBathRoom === a.serialNum ||
                            x.sensorIdMainRoom === a.serialNum
                    )
                )}
                heading='Kvitter alarm for Test'
            />
        </>
    );
};
