import * as React from 'react';
import {
    FormControl,
    Grid,
    Heading,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    useToast,
} from '@chakra-ui/react';
import { ModalViewModel } from 'models/types/ModalViewModel';
import { ButtonVariant } from 'models/types/ButtonModel';
import axios from 'axios';
import { ObservationDto } from 'models/dtos/ObservationDto';
import { env } from 'env';
import { State } from 'models/types/Checkbox';
import { Button } from '../buttons/Button';
import { useAuthContext } from '../context/AuthContext';
import { CheckboxComponent } from '../sharedComponents/Checkbox';

const initialCheckboxState: State = {
    forvirret: 0,
    irritert: 0,
    brautendeAdferd: 0,
    fysiskeTrusler: 0,
    verbaleTrusler: 0,
    slagSparkMotInventar: 0,
};

const initialState = {
    reportedBy: '',
    observationDate: new Date().toISOString().split('T')[0],
    observationTime: new Date().toTimeString().split(' ')[0].substring(0, 5),
    initialCheckboxState,
};

export const RegisterBVCObservationModal: React.FC<ModalViewModel> = ({
    isOpen,
    onCloseCallback,
    roomId,
}) => {
    const toast = useToast();
    const [reportedBy, setReportedBy] = React.useState(initialState.reportedBy);
    const [observationDate, setObservationDate] = React.useState(
        initialState.observationDate
    );
    const token = useAuthContext();
    const [observationTime, setObservationTime] = React.useState(
        initialState.observationTime
    );
    const [observationBVCs, setObservationBVCs] = React.useState<State>(
        initialState.initialCheckboxState
    );

    async function SaveObservation(observation: ObservationDto): Promise<void> {
        const options = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token.token}`,
            },
        };
        try {
            await axios.post(
                `${env.REACT_APP_API_URL}/observations`,
                observation,
                options
            );
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    throw new Error(
                        `Error: ${error.response.data}. Ta kontakt med support om problemet vedvarer`
                    );
                } else if (error.request) {
                    throw new Error(
                        'En feil skjedde, ta kontakt med support om problemet vedvarer'
                    );
                } else {
                    throw new Error(
                        'En feil skjedde, ta kontakt med support om problemet vedvarer'
                    );
                }
            }
            throw error;
        }
    }

    React.useEffect(() => {
        if (!isOpen) {
            setReportedBy('');
            setObservationBVCs(initialCheckboxState);
        } else {
            const currentDate = new Date();
            const date = currentDate.toISOString().split('T')[0];
            const time = currentDate
                .toTimeString()
                .split(' ')[0]
                .substring(0, 5);
            setObservationDate(date);
            setObservationTime(time);
            setReportedBy(initialState.reportedBy);
            setObservationBVCs(initialState.initialCheckboxState);
        }
    }, [isOpen]);

    function isValidForm(): boolean {
        const currentDate = new Date();
        const enteredDate = new Date(`${observationDate}T${observationTime}`);

        if (reportedBy === '') {
            toast({
                position: 'bottom',
                description: 'Brukernavn kan ikke være tomt',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            return false;
        }

        if (enteredDate > currentDate) {
            toast({
                position: 'bottom',
                description: 'Tidspunkt kan ikke være frem i tid',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            return false;
        }
        return true;
    }

    const onSubmit = async () => {
        if (!isValidForm()) return;
        const {
            forvirret,
            irritert,
            brautendeAdferd,
            fysiskeTrusler,
            verbaleTrusler,
            slagSparkMotInventar,
        } = observationBVCs;

        const observation = {
            description: 'BVC',
            timestamp: new Date(`${observationDate}T${observationTime}:00`),
            roomId: roomId ? parseInt(roomId, 10) : -1,
            reportedBy,
            madrs: 0,
            bvC0: forvirret,
            bvC1: irritert,
            bvC2: brautendeAdferd,
            bvC3: fysiskeTrusler,
            bvC4: verbaleTrusler,
            bvC5: slagSparkMotInventar,
        };

        if (reportedBy !== '') {
            try {
                await SaveObservation(observation);
                onCloseCallback();
            } catch (error: unknown) {
                let message = 'En uventet feil oppstod';
                if (error instanceof Error) {
                    message = error.message;
                }

                toast({
                    position: 'bottom',
                    description: message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        }
    };

    const names = [
        'forvirret',
        'irritert',
        'brautendeAdferd',
        'fysiskeTrusler',
        'verbaleTrusler',
        'slagSparkMotInventar',
    ];

    const descriptions = [
        '(Mer) forvirret',
        '(Mer) irritabel',
        '(Mer) brautende adferd',
        '(Oftere) fysiske trusler',
        '(Oftere) verbale trusler',
        '(Oftere) slag, spark etc. mot inventar',
    ];

    const toggleCheckbox = (name: keyof State) => {
        setObservationBVCs((prevState) => ({
            ...prevState,
            [name]: prevState[name] === 0 ? 1 : 0,
        }));
    };

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onCloseCallback}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Registrering av BVC kartlegging</ModalHeader>
                <ModalCloseButton visibility='hidden' />
                <ModalBody>
                    <Heading size='sm' mt={8} mb={4} fontWeight='400'>
                        <b>Aggresjon (BVC)</b> - valgfritt
                    </Heading>
                    <Stack spacing={5} direction='column'>
                        {names.map((name, i) => (
                            <CheckboxComponent
                                key={name}
                                name={name}
                                state={observationBVCs}
                                handleFunction={toggleCheckbox}
                            >
                                {descriptions[i]}
                            </CheckboxComponent>
                        ))}
                    </Stack>
                    <Grid templateColumns='126px 187px' columnGap='18px' mt={6}>
                        <Input
                            required
                            value={observationTime}
                            onInput={(e) => {
                                setObservationTime(
                                    (e.target as HTMLInputElement).value
                                );
                            }}
                            height='52px'
                            type='time'
                        />

                        <Input
                            required
                            value={observationDate}
                            onInput={(e) => {
                                setObservationDate(
                                    (e.target as HTMLInputElement).value
                                );
                            }}
                            height='52px'
                            type='date'
                        />
                    </Grid>
                    <Heading size='sm' mt={8}>
                        Brukernavn
                    </Heading>
                    <FormControl isInvalid={reportedBy === ''}>
                        <Input
                            required
                            value={reportedBy}
                            onInput={(e) =>
                                setReportedBy(
                                    (e.target as HTMLInputElement).value
                                )
                            }
                            maxW='160px'
                            my={2}
                        />
                    </FormControl>
                </ModalBody>

                <ModalFooter justifyContent='flex-start' gridGap={4}>
                    <Button
                        variant={ButtonVariant.primary}
                        onClickCallback={onSubmit}
                        text='Ok'
                    />
                    <Button
                        variant={ButtonVariant.secondary}
                        onClickCallback={onCloseCallback}
                        text='Avbryt'
                    />
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
