import * as React from 'react';
import { Box, Center, Grid } from '@chakra-ui/react';
import { useGetAlarm, useMeasurement, useRoomStatus } from 'api/Hooks';
import { RoomDto } from 'models/dtos/RoomDto';
import { RoomListMonitoringOnItem } from './RoomListMonitoringOnItem';
import { RoomListMonitoringOffItem } from './RoomListMonitoringOffItem';

interface RefreshBlockerProps {
    setRefreshBlocker: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RoomList: React.FC<RefreshBlockerProps> = ({
    setRefreshBlocker,
}) => {
    const { data: roomData } = useRoomStatus();
    const filteredRooms = roomData?.filter(
        (room: RoomDto) =>
            room.monitoringEnabled === true &&
            room.monitoringEnabled !== undefined
    );

    const { data: alarmData, status } = useGetAlarm();
    const { data: measurementData } = useMeasurement(filteredRooms);

    const roomRefs = React.useRef<Record<string, HTMLDivElement | null>>({});

    function handleAlarm(roomId: string) {
        roomRefs.current[roomId]?.scrollIntoView();
    }

    React.useEffect(() => {
        if (status === 'success') {
            alarmData?.forEach((alarm) => {
                if (alarm.roomId) {
                    handleAlarm(alarm.roomId);
                }
            });
        }
    }, [status, alarmData]);

    return (
        <Grid
            templateColumns='auto'
            minH='calc(100vh - 130px)'
            bg='staySafe.lightPeach'
        >
            <Box paddingTop='64px'>
                <Center>
                    <Box textStyle='h1'>Rom med sensor</Box>
                </Center>
                <Box padding='24px'>
                    {roomData?.map((room: RoomDto) =>
                        room.monitoringEnabled === true ? (
                            <div
                                key={`off-item-${room.id}`}
                                ref={(ref) => {
                                    roomRefs.current[room.id] = ref;
                                }}
                            >
                                <RoomListMonitoringOnItem
                                    setRefreshBlocker={setRefreshBlocker}
                                    key={room.id}
                                    id={room.id}
                                    name={room.name}
                                    sensorIdBathRoom={room.sensorIdBathRoom}
                                    sensorIdMainRoom={room.sensorIdBathRoom}
                                    presenceSleepstate={
                                        measurementData &&
                                        measurementData[room.id]?.presenceStatus
                                    }
                                    movement={
                                        measurementData &&
                                        measurementData[room.id]?.movement
                                    }
                                    heartRate={
                                        measurementData &&
                                        measurementData[room.id]?.heartRate
                                    }
                                    respiration={
                                        measurementData &&
                                        measurementData[room.id]?.breathingRate
                                    }
                                    alarm={alarmData?.find(
                                        (x) =>
                                            x.serialNum ===
                                                room.sensorIdMainRoom ||
                                            x.serialNum ===
                                                room.sensorIdBathRoom
                                    )}
                                />
                            </div>
                        ) : (
                            <div
                                key={`off-item-${room.id}`}
                                ref={(ref) => {
                                    roomRefs.current[room.id] = ref;
                                }}
                            >
                                <RoomListMonitoringOffItem
                                    id={room.id}
                                    key={room.id}
                                    name={room.name}
                                    alarm={alarmData?.find(
                                        (x) =>
                                            x.serialNum ===
                                                room.sensorIdMainRoom ||
                                            x.serialNum ===
                                                room.sensorIdBathRoom
                                    )}
                                    setRefreshBlocker={setRefreshBlocker}
                                />
                            </div>
                        )
                    )}
                </Box>
            </Box>
        </Grid>
    );
};
