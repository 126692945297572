import React from 'react';
import { Checkbox } from '@chakra-ui/react';
import { ICheckboxComponentProps, State } from 'models/types/Checkbox';

const CheckboxComponent: React.FC<ICheckboxComponentProps> = ({
    name,
    state,
    handleFunction,
    children,
}) => (
    <Checkbox
        name={name}
        defaultIsChecked={state[name as keyof State] === 1}
        isChecked={state[name as keyof State] === 1}
        onChange={() => handleFunction(name as keyof State)}
    >
        {children}
    </Checkbox>
);

export { CheckboxComponent };
