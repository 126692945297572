export enum PresenceSleepstateType {
    notPresent = 1,
    awake = 2,
    asleep = 3,
    unknown = 9,
}

export interface SleepstateViewModel {
    presenceSleepstate?: PresenceSleepstateType;
}
