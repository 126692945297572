import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { Outlet } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function PrivateRoute() {
    const auth = useAuth();
    const toast = useToast();

    useEffect(() => {
        if (!auth.isAuthenticated) {
            toast({
                title: 'Du må logge inn',
                description: 'Siden krever at du er innlogget',
                status: 'info',
                isClosable: true,
                position: 'top',
            });
            auth.signinRedirect();
        }
    }, [auth.isAuthenticated, auth, toast]);

    return <> {auth.isAuthenticated && <Outlet />}</>;
}
export default PrivateRoute;
