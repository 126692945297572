import {
    getMeasurement,
    getRoomStatus,
    getMeasurementValues,
    getOneRoom,
    getSleepStageDuration,
    getSleepDistribution,
    getSleepOverview,
    getSleepReport,
    getAlarm,
    cancelAlarm,
    saveUpdateMonitoring,
} from 'api/Requests';

export default {
    getMeasurement,
    getMeasurementValues,

    getAlarm,
    cancelAlarm,

    getSleepDistribution,
    getSleepOverview,
    getSleepReport,
    getSleepStageDuration,

    getRoomStatus,
    getOneRoom,

    saveUpdateMonitoring,
};
