import * as React from 'react';

import { Route, Routes } from 'react-router-dom';

import '@fontsource/open-sans/400.css';
import { useAuth } from 'react-oidc-context';
import { useEffect, useState } from 'react';
import { RoomList } from './pages/RoomList/RoomList';
import OidcRedirect from './pages/OidcRedirect';
import { RegisterObservation } from './pages/Observations/RegisterObservation';
import { RoomDetailOverview } from './pages/RoomDetail/RoomDetailOverview';
import PrivateRoute from './common/utils/PrivateRoute';
import { Layout } from './pages/Layout';
import { AuthErrorPage } from './pages/AuthErrorPage';

export const App: React.FC = () => {
    const auth = useAuth();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authError, setAuthError] = useState(false);
    const [refreshBlockerActive, setRefreshBlocker] = useState(false);
    const retryDelay = 5000;
    const refreshInterval =
        parseInt(process.env.REACT_APP_REFRESH_INTERVAL_SECONDS || '60', 10) *
        1000;

    useEffect(() => {
        const interval = setInterval(() => {
            if (!refreshBlockerActive) window.location.reload();
        }, refreshInterval);

        return () => clearInterval(interval);
    }, [refreshInterval, refreshBlockerActive]);

    useEffect(() => {
        if (authError) return;
        const checkAuth = async () => {
            try {
                if (auth.user) {
                    if (auth.user.expired) {
                        await auth.signinRedirect();
                    } else {
                        setIsAuthenticated(true);
                    }
                } else if (!auth.isLoading) {
                    await auth.signinRedirect();
                }
            } catch (error) {
                setAuthError(true);
                setTimeout(() => {
                    setAuthError(false); // Clear error to allow retry
                }, retryDelay);
            }
        };
        checkAuth();
    }, [auth, authError]);

    if (authError) {
        return <AuthErrorPage />;
    }

    return !auth.isLoading ? (
        <Routes>
            <Route element={<Layout />}>
                {isAuthenticated ? (
                    <>
                        <Route path='/' element={<PrivateRoute />}>
                            <Route
                                path='/'
                                element={
                                    <RoomList
                                        setRefreshBlocker={setRefreshBlocker}
                                    />
                                }
                            />
                        </Route>
                        <Route path='/room/:roomId' element={<PrivateRoute />}>
                            <Route
                                path='/room/:roomId'
                                element={<RoomDetailOverview />}
                            />
                        </Route>
                        <Route
                            path='/observation/:roomId'
                            element={<PrivateRoute />}
                        >
                            <Route
                                path='/observation/:roomId'
                                element={<RegisterObservation />}
                            />
                        </Route>
                    </>
                ) : (
                    <Route path='/oauth-redirect' element={<OidcRedirect />} />
                )}
            </Route>
        </Routes>
    ) : (
        <></>
    );
};
