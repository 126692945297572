import { Box, VStack, HStack, Spacer } from '@chakra-ui/react';
import { SleepViewDto } from 'models/dtos/SleepDto';
import React from 'react';
import { useSleepReport } from 'api/Hooks';
// import { formatTime } from '../utils/formatTime';
import { convertUTCToLocalTime } from '../utils/convertUTCToLocalTime';

export const SleepReport: React.FC<SleepViewDto> = ({
    startDate,
    sensorIdMainRoom,
    sensorIdBathRoom,
}) => {
    const { data: sleepReport } = useSleepReport(
        startDate,
        sensorIdMainRoom,
        sensorIdBathRoom
    );

    const hours = !sleepReport ? 0 : sleepReport?.timeAsleep / 3600;
    const nHours = Math.floor(hours);
    const minutes = (hours - nHours) * 60;
    const nMinutes = Math.round(minutes);
    const sleepingPeriod = `${nHours}t ${nMinutes}min`;
    const timestampWakeUp = sleepReport && sleepReport.timeAtWakeup;

    let contentWakeUp: JSX.Element | string;
    if (timestampWakeUp) {
        const wakeUpTime = new Date(
            convertUTCToLocalTime(timestampWakeUp.toString())
        );
        contentWakeUp = wakeUpTime.toLocaleTimeString();
    } else {
        contentWakeUp = 'ikke registrert data';
    }
    const timestampSleep = sleepReport && sleepReport.timeAtSleep;

    let contentAsleep: JSX.Element | string;
    if (timestampSleep) {
        const sleepTime = new Date(
            convertUTCToLocalTime(timestampSleep.toString())
        );
        contentAsleep = sleepTime.toLocaleTimeString();
    } else {
        contentAsleep = 'ikke registrert data';
    }

    return (
        <Box
            borderWidth={2}
            backgroundColor='white'
            borderColor='black'
            marginTop='16px'
            padding={4}
        >
            <VStack alignItems='flex-start' width='full'>
                <HStack w='full' spacing={4}>
                    <Box>Sovnet (første gang)</Box>
                    <Spacer />
                    <Box>{contentAsleep}</Box>
                </HStack>
                <HStack w='full' spacing={4}>
                    <Box>Våknet (siste gang)</Box>
                    <Spacer />
                    <Box>{contentWakeUp}</Box>
                </HStack>
                <HStack w='full' spacing='auto'>
                    <Box>Total søvnperiode</Box>
                    <Spacer />
                    <Box>{sleepingPeriod}</Box>
                </HStack>
            </VStack>
        </Box>
    );
};
