import {
    Table,
    Tr,
    Th,
    TableContainer,
    Thead,
    Tbody,
    Td,
} from '@chakra-ui/react';
import axios, { AxiosResponse } from 'axios';
import { ObservationBVCDto } from 'models/dtos/ObservationDto';
import { BVCType } from 'models/types/ObservationType';
import { env } from 'env';
import React from 'react';
import { useAuthContext } from '../context/AuthContext';

export const BVCTable: React.FC<BVCType> = ({ startDate, roomId }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [BVC, setBVC] = React.useState<ObservationBVCDto[]>([]);
    const { token } = useAuthContext();

    React.useEffect(() => {
        function getObservationValues() {
            const endDate = JSON.stringify(new Date(Date.now())).replace(
                /"/g,
                ''
            );
            axios
                .get(
                    `${env.REACT_APP_API_URL}/observations?roomId=${roomId}&start=${startDate}&end=${endDate}&observationType=BVC`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((response: AxiosResponse) => {
                    setBVC(response.data);
                });
        }
        getObservationValues();
    }, [roomId, startDate, token]);

    return (
        // TODO Fikse bredden på tabell og chart
        <>
            <TableContainer maxWidth='1040px'>
                <Table variant='simple' backgroundColor='white'>
                    <Thead>
                        <Tr>
                            <Th>Tidspunkt</Th>
                            {BVC.map((item) => {
                                return (
                                    <Th>
                                        {`
                            ${item.timeStamp
                                .toLocaleString()
                                ?.split('T')[0]
                                .split('-')
                                .reverse()
                                .join('-')}
                            kl
                            ${item.timeStamp.toLocaleString().split('T')[1]}
                            `}
                                    </Th>
                                );
                            })}
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>TotalScore</Td>
                            {BVC.map((item) => {
                                // eslint-disable-next-line no-nested-ternary
                                return item.total >= 4 ? (
                                    <Td backgroundColor='staySafe.pink'>
                                        {item.total}
                                    </Td>
                                ) : item.total > 0 && item.total < 4 ? (
                                    <Td backgroundColor='staySafe.peach'>
                                        {item.total}
                                    </Td>
                                ) : (
                                    <Td backgroundColor='staySafe.white'>
                                        {item.total}
                                    </Td>
                                );
                            })}
                        </Tr>
                        <Tr>
                            <Td>Forvirret</Td>
                            {BVC.map((item) => {
                                return <Td>{item.bvC0}</Td>;
                            })}
                        </Tr>
                        <Tr>
                            <Td>Irritabel</Td>
                            {BVC.map((item) => {
                                return <Td>{item.bvC1}</Td>;
                            })}
                        </Tr>
                        <Tr>
                            <Td>Brautende atferd</Td>
                            {BVC.map((item) => {
                                return <Td>{item.bvC2}</Td>;
                            })}
                        </Tr>
                        <Tr>
                            <Td>Fysiske trusler</Td>
                            {BVC.map((item) => {
                                return <Td>{item.bvC3}</Td>;
                            })}
                        </Tr>
                        <Tr>
                            <Td>Verbale trusler</Td>
                            {BVC.map((item) => {
                                return <Td>{item.bvC4}</Td>;
                            })}
                        </Tr>
                        <Tr>
                            <Td>Slag, spark etc. mot inventar</Td>
                            {BVC.map((item) => {
                                return <Td>{item.bvC5}</Td>;
                            })}
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    );
};
