import * as React from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Heading,
    VStack,
    Input,
    Checkbox,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { ButtonVariant } from 'models/dtos/ButtonModel';

export interface DiagnoseFieldModel {
    onDiagnoseChange: (diagnose: string) => void;
}

export const DiagnoseFields: React.FC<DiagnoseFieldModel> = ({
    onDiagnoseChange,
}) => {
    const [diagnose, setDiagnose] = React.useState({
        [Math.random().toString()]: '',
    });
    const [checked, setChecked] = React.useState(false);

    // const regexp = /^([a-tA-T]|[v-zV-Z])\d[a-zA-Z0-9](\.[a-zA-Z0-9]{1,4})?$/;

    function removeRepeaterAtIndex(fieldId: string) {
        const item = { ...diagnose };
        delete item[fieldId];

        setDiagnose(item);

        onDiagnoseChange(Object.values(item).join(';'));
    }

    function onChange(input: string, fieldId: string) {
        const newValue = { ...diagnose, [fieldId]: input };
        setDiagnose(newValue);

        onDiagnoseChange(Object.values(newValue).join(';'));
    }

    function checkInvalid(key: string) {
        if (checked) {
            return false;
        }
        if (diagnose[key] !== '') {
            return false;
        }
        return true;
    }

    return (
        <>
            {Object.keys(diagnose).map((key: string, index: number) => (
                <Box key={key}>
                    <Heading size='sm' mt={8}>
                        Diagnose
                    </Heading>
                    <FormControl mb={4}>
                        <VStack alignItems='flex-start'>
                            Uavklart
                            <Checkbox
                                name='checkbox'
                                type='checkbox'
                                isChecked={checked}
                                onChange={(e) => {
                                    const newChecked = e.target.checked;
                                    setChecked(newChecked);
                                    if (newChecked) {
                                        onDiagnoseChange('uavklart');
                                    } else {
                                        onDiagnoseChange('');
                                    }
                                }}
                            >
                                Uavklart
                            </Checkbox>
                            <Input
                                isInvalid={checkInvalid(key)}
                                id='diagnosisInput'
                                disabled={checked}
                                value={diagnose[key]}
                                onInput={(e) =>
                                    onChange(
                                        (e.target as HTMLInputElement).value,
                                        key
                                    )
                                }
                                maxW='160px'
                                mt={2}
                            />
                            {index > 0 && (
                                <DeleteIcon
                                    ml={4}
                                    _hover={{ color: 'staySafe.red' }}
                                    transition='color 100ms ease-in-out'
                                    onClick={() => removeRepeaterAtIndex(key)}
                                />
                            )}
                        </VStack>
                    </FormControl>
                </Box>
            ))}
            <FormLabel>ICD-10 Diagnosekode</FormLabel>
            <Button
                variant={ButtonVariant.link}
                rightIcon={<AddIcon ml={2} />}
                textDecoration='underline'
                _hover={{ textDecoration: 'none' }}
                onClick={() => onChange('', Math.random().toString())}
            >
                Legg til diagnose
            </Button>
        </>
    );
};
