import * as React from 'react';
import { ModalViewModelExtended } from 'models/types/ModalViewModel';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';

export const MonitoringModal: React.FC<ModalViewModelExtended> = ({
    title,
    content,
    isOpen,
    onCloseCallback,
    closeButtonText = 'Lukk',
}) => {
    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onCloseCallback}
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton visibility='hidden' />
                <ModalBody>{content}</ModalBody>

                <ModalFooter>
                    <Button variant='text' onClick={onCloseCallback}>
                        {closeButtonText}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
