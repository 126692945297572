import React, { ReactElement } from 'react';

import { Header } from 'components/common/utils/Header';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

export const Layout = (): ReactElement => {
    return (
        <>
            <Header />
            <Box>
                <Outlet />
            </Box>
        </>
    );
};
