import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { RoomChartViewModel } from 'models/dtos/RoomChartView';
import { useMeasurementValues } from '../../../api/Hooks';

export const RoomChart: React.FC<RoomChartViewModel> = ({
    type,
    chartTitle,
    yAxisUnit,
    sensorIdMainRoom,
    sensorIdBathRoom,
    startDate,
}) => {
    const resultCount = 30;

    const { data } = useMeasurementValues(
        type,
        startDate,
        resultCount,
        sensorIdBathRoom,
        sensorIdMainRoom
    );

    const series1 = data
        ?.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
        .filter((value) => value.locationId === 1)
        .map((entry) => {
            const parsedDate: number = new Date(
                entry.timestamp.toString()
            ).getTime();

            const result = {
                x: parsedDate,
                y: entry.value,
            };
            return result;
        });

    const series2 = data
        ?.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
        .filter((value) => value.locationId === 2)
        .map((entry) => {
            const parsedDate: number = new Date(
                entry.timestamp.toString()
            ).getTime();
            const result = {
                x: parsedDate,
                y: entry.value,
            };
            return result;
        });

    const series = [];
    if (series1 !== undefined && series1?.length > 0) {
        series.push({
            name: data?.find((entry) => entry.locationId === 1)?.location,
            data: series1,
            dataGrouping: {},
        });
    }
    if (series2 !== undefined && series2?.length > 0) {
        series.push({
            name: data?.find((entry) => entry.locationId === 2)?.location,
            data: series2,
            dataGrouping: {},
        });
    }

    const chart = {
        title: {
            text: chartTitle,
        },
        xAxis: {
            type: 'datetime',
        },
        yAxis: {
            title: {
                text: yAxisUnit,
            },
            min: 0,
            max: 100,
        },
        series,
        credits: {
            enabled: false,
        },
        time: {
            useUTC: false,
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={chart} />;
};
