import * as React from 'react';
import {
    Checkbox,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Stack,
    useToast,
} from '@chakra-ui/react';
import { MonitoringDto } from 'models/dtos/MonitoringDto';
// import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import { env } from 'env';
import { ModalViewModel } from 'models/types/ModalViewModel';
import { ButtonVariant } from 'models/types/ButtonModel';
import { useSaveUpdateMonitoring } from 'api/Hooks';
import { Button } from '../buttons/Button';
import { DiagnoseFields } from '../monitoring/MonitoringDiagnoseField';

// function saveMonitoring(monitoring: MonitoringDto): void {
//     axios.post(
//         `${env.REACT_APP_API_URL}/room/monitoring
//         `,
//         monitoring
//     );
// }

export const StartMonitoringModal: React.FC<ModalViewModel> = ({
    isOpen,
    onCloseCallback,
    roomId,
    setRefreshBlocker,
}) => {
    const navigate = useNavigate();
    const toast = useToast();
    const { mutate: saveMonitoring } = useSaveUpdateMonitoring();
    function isValidForm(monitoring: MonitoringDto): boolean {
        if (
            (monitoring.birthYear &&
                monitoring.birthYear.toString().length !== 4) ||
            monitoring.birthYear.toString() === 'NaN' ||
            +monitoring.birthYear.toString() < 1900
        ) {
            toast({
                position: 'top',
                description: 'Fødselsår må være et årstall på 4 sifre',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            return false;
        }
        if (monitoring.gender === '') {
            toast({
                position: 'top',
                description: 'Kjønn må velges',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            return false;
        }
        if (monitoring.suicideRisk === '') {
            toast({
                position: 'top',
                description: 'Selvmordsfare må velges',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            return false;
        }
        if (monitoring.diagnose === '') {
            toast({
                position: 'top',
                description: 'Diagnose må enten legges til eller være uavklart',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            return false;
        }

        if (monitoring.npr === '') {
            toast({
                position: 'top',
                description: 'NPR må fylles ut',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            return false;
        }
        if (monitoring.reportedBy === '') {
            toast({
                position: 'top',
                description: 'Brukernavn må fylles ut',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            return false;
        }
        return true;
    }

    const [birthYear, setBirthYear] = React.useState('');
    const [gender, setGender] = React.useState('');
    const [diagnose, setDiagnose] = React.useState('');
    const [reportedBy, setReportedBy] = React.useState('');
    const [nprValue, setNprValue] = React.useState('');
    const [suicideRisk, setSuicideRisk] = React.useState('');
    const [monitoringBVCs, setMonitoringBVCs] = React.useState([
        { 'bvc0': false },
        { 'bvc1': false },
        { 'bvc2': false },
        { 'bvc3': false },
        { 'bvc4': false },
        { 'bvc5': false },
    ]);

    const onSubmit = () => {
        const monitoring = {
            roomId: roomId ? parseInt(roomId, 10) : -1,
            enable: true,
            npr: nprValue,
            birthYear: parseInt(birthYear, 10),
            suicideRisk,
            diagnose,
            gender,
            reportedBy,
        };

        Object.assign(monitoring, ...monitoringBVCs);

        if (isValidForm(monitoring)) {
            saveMonitoring(monitoring);
            if (setRefreshBlocker) {
                setRefreshBlocker(false);
            }
            navigate(`/room/${roomId}`);
        }
    };

    function handleCheckboxChange(target: HTMLInputElement, index: number) {
        const items = [...monitoringBVCs];
        const item = {
            ...items[index],
            [target.name]: target.checked,
        };
        items[index] = item;

        setMonitoringBVCs(items);
    }

    const onDiagnoseChange = (diagnoseInput: string) => {
        setDiagnose(diagnoseInput);
    };

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onCloseCallback}
            size='xl'
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader pb={0}>Start monitorering</ModalHeader>
                <ModalCloseButton visibility='hidden' />

                <ModalBody>
                    <FormLabel>
                        Alle felt må fylles ut, bortsett fra de som er markert
                        som valgfrie.
                    </FormLabel>

                    <Heading size='sm' mt={8}>
                        Fødselsår
                    </Heading>
                    <FormControl isInvalid={birthYear.length !== 4}>
                        <Input
                            required
                            value={birthYear}
                            onInput={(e) =>
                                setBirthYear(
                                    (e.target as HTMLInputElement).value
                                )
                            }
                            maxW='100px'
                            type='number'
                            mt={2}
                        />
                    </FormControl>

                    <Heading size='sm' mt={8}>
                        Kjønn
                    </Heading>
                    <FormControl isInvalid={gender === ''}>
                        <RadioGroup mt={4} onChange={setGender} value={gender}>
                            <Stack spacing={4}>
                                <Radio value='M'>Mann</Radio>
                                <Radio value='F'>Kvinne</Radio>
                                <Radio value='U'>Annet</Radio>
                            </Stack>
                        </RadioGroup>
                    </FormControl>

                    <Heading size='sm' mt={8}>
                        Selvmordsfare
                    </Heading>
                    <FormControl isInvalid={suicideRisk === ''}>
                        <RadioGroup
                            mt={4}
                            onChange={setSuicideRisk}
                            value={suicideRisk}
                        >
                            <Stack spacing={4}>
                                <Radio value='0'>Lav</Radio>
                                <Radio value='1'>Forhøyet</Radio>
                            </Stack>
                        </RadioGroup>
                    </FormControl>

                    <DiagnoseFields onDiagnoseChange={onDiagnoseChange} />

                    <Heading size='sm' mt={8}>
                        NPR
                    </Heading>
                    <FormControl isInvalid={nprValue === ''}>
                        <Input
                            required
                            value={nprValue}
                            onInput={(e) =>
                                setNprValue(
                                    (e.target as HTMLInputElement).value
                                )
                            }
                            maxW='160px'
                            mt={2}
                        />
                    </FormControl>

                    <Heading size='sm' mt={8} mb={4} fontWeight='400'>
                        <b>Aggresjon (BVC)</b> - valgfritt
                    </Heading>
                    <Stack spacing={4}>
                        <Checkbox
                            name='bvc0'
                            onChange={(e) => handleCheckboxChange(e.target, 0)}
                        >
                            Forvirret
                        </Checkbox>
                        <Checkbox
                            name='bvc1'
                            onChange={(e) => handleCheckboxChange(e.target, 1)}
                        >
                            Irritabel
                        </Checkbox>
                        <Checkbox
                            name='bvc2'
                            onChange={(e) => handleCheckboxChange(e.target, 2)}
                        >
                            Brautende adferd
                        </Checkbox>
                        <Checkbox
                            name='bvc3'
                            onChange={(e) => handleCheckboxChange(e.target, 3)}
                        >
                            Fysiske trusler
                        </Checkbox>
                        <Checkbox
                            name='bvc4'
                            onChange={(e) => handleCheckboxChange(e.target, 4)}
                        >
                            Verbale trusler
                        </Checkbox>
                        <Checkbox
                            name='bvc5'
                            onChange={(e) => handleCheckboxChange(e.target, 5)}
                        >
                            Slag, spark etc. mot inventar
                        </Checkbox>
                    </Stack>

                    <Heading size='sm' mt={8}>
                        Brukernavn
                    </Heading>
                    <FormControl isInvalid={reportedBy === ''}>
                        <Input
                            required
                            value={reportedBy}
                            onInput={(e) =>
                                setReportedBy(
                                    (e.target as HTMLInputElement).value
                                )
                            }
                            maxW='160px'
                            my={2}
                        />
                    </FormControl>
                </ModalBody>
                <ModalFooter justifyContent='flex-start' gridGap={4}>
                    <Button
                        variant={ButtonVariant.primary}
                        onClickCallback={onSubmit}
                        text='Start Monitorering'
                    />
                    <Button
                        variant={ButtonVariant.secondary}
                        onClickCallback={onCloseCallback}
                        text='Avbryt'
                    />
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
