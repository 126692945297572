import * as React from 'react';
import { Box, Text } from '@chakra-ui/react';

export interface MeasurementViewModel {
    text: string;
    unit: string;
    value: number | null | undefined;
    icon: React.ReactNode;
}

export const Measurement: React.FC<MeasurementViewModel> = ({
    text,
    unit,
    value,
    icon,
}) => {
    return (
        <Box display='flex' fontSize='16px' alignItems='baseline'>
            <Box paddingRight='4px'>{icon}</Box>
            {text}:
            <Text fontSize='32px' paddingLeft='4px' paddingRight='4px'>
                {value && value > 0 ? value : 'X'}
            </Text>
            {unit}
        </Box>
    );
};
