import * as React from 'react';
import {
    Box,
    Button,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import { ModalViewModel } from 'models/views/RoomViewModel';

export const AlarmModal: React.FC<ModalViewModel> = ({
    show,
    onClose,
    onSubmit,
    heading,
    user,
    alarm,
}) => {
    return (
        <Modal isOpen={show} onClose={() => onClose(false)}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>
                    <Box display='inline-flex'>
                        <Heading size='md' marginLeft={2} color='OCTypo'>
                            {heading}
                        </Heading>
                    </Box>
                </ModalHeader>

                <ModalBody>
                    Alarmen vil fjernes for alle ansatte om du kvitterer
                    alarmen.{' '}
                </ModalBody>
                <ModalFooter>
                    <Button
                        ml={3}
                        onClick={() => {
                            onClose(false);
                        }}
                    >
                        Avbryt
                    </Button>
                    <Button
                        colorScheme='red'
                        ml={3}
                        onClick={() => {
                            onSubmit({
                                alarmId: alarm?.alarmId || '',
                                active: false,
                                cancelledBy: user,
                            });
                        }}
                    >
                        Kvitter alarm
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
