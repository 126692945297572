import * as React from 'react';
import { Box, Button as ChakraButton } from '@chakra-ui/react';
import {
    ButtonType,
    ButtonVariant,
    ButtonViewModel,
} from 'models/types/ButtonModel';

export const Button: React.FC<ButtonViewModel> = ({
    text,
    type = ButtonType.button,
    variant = ButtonVariant.primary,
    onClickCallback,
    leftIcon,
    rightIcon,
}) => {
    if (leftIcon) {
        return (
            <ChakraButton
                onClick={onClickCallback}
                type={type}
                variant={variant}
                paddingLeft='0'
                paddingRight='6px'
            >
                <Box paddingRight='8px'>{leftIcon}</Box>
                {text}
            </ChakraButton>
        );
    }

    if (rightIcon) {
        return (
            <ChakraButton
                onClick={onClickCallback}
                type={type}
                variant={variant}
                paddingLeft='6px'
                paddingRight='0'
            >
                {text}
                <Box paddingLeft='8px'>{rightIcon}</Box>
            </ChakraButton>
        );
    }

    return (
        <ChakraButton
            onClick={onClickCallback}
            type={type}
            variant={variant}
            paddingY={6}
            paddingX={8}
        >
            {text}
        </ChakraButton>
    );
};
