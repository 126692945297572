import * as React from 'react';
import { Box, Flex, Grid, Text, useDisclosure } from '@chakra-ui/react';
import { useNavigate, Link } from 'react-router-dom';
import { FiWind } from 'react-icons/fi';
import { FaRegBell } from 'react-icons/fa';
import { MdDirectionsWalk } from 'react-icons/md';
import { Room } from 'models/views/RoomViewModel';
import { Measurement } from 'components/common/measurements/Measurement';
import { Sleepstate } from 'components/common/sleepstate/Sleepstate';
import { Button } from 'components/common/buttons/Button';
// import { MonitoringHandler } from 'components/common/monitoring/MonitoringHandler';
import { MonitoringModal } from 'components/common/modals/MonitoringModal';
import { RegisterBVCObservationModal } from 'components/common/modals/RegisterBVCObservationModal';
import { AlarmModal } from 'components/common/modals/AlarmModal';
import { ButtonVariant } from 'models/dtos/ButtonModel';
import { useAuth } from 'react-oidc-context';
import { useCancelAlarm, useSaveUpdateMonitoring } from 'api/Hooks';

export const RoomListMonitoringOnItem: React.FC<Room> = ({
    id,
    name = '',
    presenceSleepstate,
    movement = null,
    // heartRate = null,
    respiration = null,
    alarm,
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: BVCIsOpen,
        onOpen: BVCOnOpen,
        onClose: BVCOnClose,
    } = useDisclosure();
    const auth = useAuth();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const userId = auth.user?.profile.username as string;

    const { mutate: cancelAlarm } = useCancelAlarm(userId);
    const { mutate: saveMonitoring } = useSaveUpdateMonitoring();

    const handleUpdateAlarm = () => {
        cancelAlarm(alarm!);
        setIsModalOpen(false);
    };

    const handleCancelMonitoring = () => {
        saveMonitoring({
            roomId: parseInt(id, 10),
            enable: false,
            npr: '',
            birthYear: parseInt('', 10),
            gender: '',
            diagnose: '',
            suicideRisk: '',
            reportedBy: '',
            bvC0: false,
            bvC1: false,
            bvC2: false,
            bvC3: false,
            bvC4: false,
            bvC5: false,
        });
    };
    return (
        <Grid
            backgroundColor={alarm?.active ? 'staySafe.rustOrange' : ''}
            onClick={
                alarm
                    ? () => {
                          setIsModalOpen(true);
                      }
                    : () => setIsModalOpen(false)
            }
            templateColumns='auto repeat(16, 100px) auto'
            templateRows='32px 32px 32px'
            rowGap='32px'
            alignItems='baseline'
        >
            <Box
                gridColumnStart='2'
                gridColumnEnd='6'
                textStyle='h2'
                onClick={() => navigate(`/room/${id}`)}
                textColor='staySafe.blue'
                cursor='pointer'
            >
                {name}
            </Box>
            <Box gridRow={2} gridColumnStart={2} gridColumnEnd={5}>
                {alarm?.active ? (
                    <Flex gap='8px'>
                        <Text>Hærverksalarm</Text>
                        <FaRegBell />
                    </Flex>
                ) : (
                    <Sleepstate presenceSleepstate={presenceSleepstate} />
                )}
            </Box>
            <Box
                gridRow={2}
                gridColumnStart={5}
                gridColumnEnd={7}
                justifyContent='start'
            >
                <Measurement
                    text='Bevegelse'
                    unit='av 100'
                    icon={<MdDirectionsWalk size='24px' />}
                    value={movement}
                />
            </Box>
            {/* 
            // Kommmerntert ut da de ikke vil at det ksal vises. Må endre grid etter dette om det skal inn igjen s
            <Box
                gridRow={2}
                gridColumnStart={7}
                gridColumnEnd={9}
                justifyContent='end'
                display='flex'
                fontSize='18px'
                alignItems='baseline'
            >
                 <Measurement
                    text='Pulsfrekvens'
                    unit='slag/min.'
                    icon={<FaRegHeart size='24px' />}
                    value={heartRate}
                /> 
            </Box> */}
            <Box
                gridRow={2}
                gridColumnStart={7}
                gridColumnEnd={10}
                justifyContent='end'
                display='flex'
                fontSize='18px'
                alignItems='baseline'
            >
                <Measurement
                    text='Respirasjon'
                    unit='pust/min.'
                    icon={<FiWind size='24px' />}
                    value={respiration}
                />
            </Box>
            <Box
                gridRow={2}
                gridColumnStart={10}
                gridColumnEnd={12}
                justifyContent='end'
                display='flex'
                fontSize='18px'
                alignItems='baseline'
            >
                <Link to={`observation/${id}`}>
                    <Text as='u' textColor='staySafe.blue'>
                        Ny observasjon +
                    </Text>
                </Link>
            </Box>
            <Box
                gridRow={2}
                gridColumnStart={12}
                justifyContent='end'
                display='flex'
                fontSize='18px'
                alignItems='baseline'
                cursor='pointer'
            >
                <Text as='u' textColor='staySafe.blue' onClick={BVCOnOpen}>
                    Ny BVC +
                </Text>
            </Box>
            <Box
                gridRow={2}
                gridColumnStart={13}
                gridColumnEnd={16}
                justifyContent='end'
                display='flex'
                fontSize='18px'
                alignItems='baseline'
            >
                <Button
                    text='Stopp monitorering'
                    variant={ButtonVariant.secondary}
                    onClickCallback={() => {
                        onOpen();
                        handleCancelMonitoring();
                    }}
                />
            </Box>
            <Box
                gridColumnStart={2}
                gridColumnEnd={16}
                borderTop='1px'
                borderColor='staySafe.darkGrey'
            />
            <MonitoringModal
                title='Monitorering er stoppet'
                content={`Ingen data fra ${name} lagres før monitorering startes igjen.`}
                isOpen={isOpen}
                onOpen={onOpen}
                onCloseCallback={() => {
                    onClose();
                    navigate('/');
                }}
            />
            <RegisterBVCObservationModal
                isOpen={BVCIsOpen}
                roomId={id}
                onOpen={BVCOnOpen}
                onCloseCallback={() => {
                    BVCOnClose();
                    navigate('/');
                }}
            />
            <AlarmModal
                show={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleUpdateAlarm}
                heading='Kvitter alarm for Test'
                alarm={alarm}
                user={`${auth.user?.profile.given_name} ${auth.user?.profile.family_name}`}
            />
        </Grid>
    );
};
