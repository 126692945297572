import React from 'react';
import { Grid, Box, Center, Button } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { ButtonVariant } from 'models/types/ButtonModel';
import { Logo } from '../logo/Logo';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Header = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const initalizeSignout = () => {
        auth.signoutRedirect();
        navigate('/');
    };
    return (
        <header>
            <Grid templateColumns='120px 242px auto 120px 120px'>
                <Box gridColumn={2}>
                    <Center minH='130px'>
                        <Link to='/'>
                            <Logo width='242px' />
                        </Link>
                    </Center>
                </Box>
                <Box gridColumn={4}>
                    <Center minH='130px'>
                        {!auth.isAuthenticated ? (
                            <Button
                                variant={ButtonVariant.primary}
                                onClick={() => auth.signinRedirect()}
                            >
                                Logg inn
                            </Button>
                        ) : (
                            <Button
                                variant={ButtonVariant.secondary}
                                onClick={initalizeSignout}
                            >
                                Logg ut
                            </Button>
                        )}
                    </Center>
                </Box>
            </Grid>
        </header>
    );
};
