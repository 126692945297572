import * as React from 'react';
import {
    Box,
    Radio,
    RadioGroup,
    Stack,
    Text,
    HStack,
    Center,
} from '@chakra-ui/react';

import { useParams } from 'react-router-dom';
import { useOneRoom } from 'api/Hooks';
import { SleepChartView } from './SleepChartView';

export const SleepDetail: React.FC = () => {
    const { roomId } = useParams();
    const { data: roomData } = useOneRoom(roomId);
    const MS_PER_DAY = 86400000;

    const startDate24t = JSON.stringify(
        new Date(Date.now() - MS_PER_DAY)
    ).replace(/"/g, '');

    const startDate7d = JSON.stringify(
        new Date(Date.now() - 7 * MS_PER_DAY)
    ).replace(/"/g, '');

    const startDate1m = JSON.stringify(
        new Date(Date.now() - 30 * MS_PER_DAY)
    ).replace(/"/g, '');

    const [value, setValue] = React.useState(
        localStorage.getItem('sleepDetailTimeFrame') || 'Døgn'
    );

    React.useEffect(() => {
        localStorage.setItem('sleepDetailTimeFrame', value);
    }, [value]);

    return (
        <Box>
            <Center>
                <Stack isInline>
                    <RadioGroup
                        value={value}
                        onChange={(nextValue: string) => setValue(nextValue)}
                    >
                        <Text>Vis siste</Text>
                        <HStack align='start'>
                            <Radio
                                backgroundColor='white'
                                value='Døgn'
                                fontSize='32px'
                            >
                                Døgn
                            </Radio>
                            <Radio
                                backgroundColor='white'
                                value='Uke'
                                fontSize='32px'
                            >
                                Uke
                            </Radio>
                            <Radio
                                backgroundColor='white'
                                value='Måned'
                                fontSize='32px'
                            >
                                Måned
                            </Radio>
                        </HStack>
                    </RadioGroup>
                </Stack>
            </Center>
            <Box paddingTop='64px' marginBottom='40px'>
                {value === 'Døgn' && (
                    <SleepChartView
                        roomId={roomId}
                        data={roomData}
                        lastDay
                        startDate={startDate24t}
                        selectedTimeframe='Day'
                    />
                )}
                {value === 'Uke' && (
                    <SleepChartView
                        roomId={roomId}
                        data={roomData}
                        lastDay={false}
                        startDate={startDate7d}
                        selectedTimeframe='Week'
                    />
                )}
                {value === 'Måned' && (
                    <SleepChartView
                        roomId={roomId}
                        data={roomData}
                        lastDay={false}
                        startDate={startDate1m}
                        selectedTimeframe='Month'
                    />
                )}
            </Box>
            {/* <Accordion marginBottom='40px' allowToggle marginTop='64px'>
                <AccordionItem>
                    <AccordionButton color='staySafe.blue'>
                        <Text marginRight='12px'>Vis tabell</Text>
                        <ArrowUpDownIcon />
                    </AccordionButton>
                    <AccordionPanel>
                        <Box
                            bg='white'
                            marginTop='16px'
                            padding='16px'
                            minWidth='1000px'
                        >
                            <SleepPhasesChart
                                roomId={roomId}
                                chartTitle='Søvnfaser siste døgn'
                                yAxisUnit='i prosent'
                                yAxisMax={100}
                                sensorIdMainRoom={roomData?.sensorIdMainRoom}
                                sensorIdBathRoom={roomData?.sensorIdBathRoom}
                                startDate={startDate24t}
                                type='SLEEPSTAGE'
                            />
                        </Box>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion> */}
        </Box>
    );
};
