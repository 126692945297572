import * as React from 'react';
import {
    Box,
    Container,
    FormControl,
    FormLabel,
    Grid,
    Heading,
    Input,
    Stack,
    Textarea,
    useToast,
} from '@chakra-ui/react';
import { FiArrowLeft } from 'react-icons/fi';
import { useParams, useNavigate } from 'react-router-dom';
import { ObservationDto } from 'models/dtos/ObservationDto';
import { Button } from 'components/common/buttons/Button';
import axios from 'axios';
import { env } from 'env';
import { useOneRoom } from 'api/Hooks';
import { ButtonType, ButtonVariant } from 'models/types/ButtonModel';
import { useAuthContext } from 'components/common/context/AuthContext';
import { CheckboxComponent } from 'components/common/sharedComponents/Checkbox';
import { State } from 'models/types/Checkbox';

const initialCheckboxState: State = {
    forvirret: 0,
    irritert: 0,
    brautendeAdferd: 0,
    fysiskeTrusler: 0,
    verbaleTrusler: 0,
    slagSparkMotInventar: 0,
};

export const RegisterObservation: React.FC = () => {
    const { roomId } = useParams();
    const token = useAuthContext();
    const { data: oneRoom } = useOneRoom(roomId);

    const toast = useToast();
    async function SaveObservation(observation: ObservationDto): Promise<void> {
        const options = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token.token}`,
            },
        };
        try {
            await axios.post(
                `${env.REACT_APP_API_URL}/observations`,
                observation,
                options
            );
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    throw new Error(
                        `Error: ${error.response.data}. Ta kontakt med support om problemet vedvarer`
                    );
                } else if (error.request) {
                    throw new Error(
                        'En feil skjedde, ta kontakt med support om problemet vedvarer'
                    );
                } else {
                    throw new Error(
                        'En feil skjedde, ta kontakt med support om problemet vedvarer'
                    );
                }
            }
            throw error;
        }
    }

    function isValidForm(observation: ObservationDto): boolean {
        const currentDate = new Date();
        if (observation.description === '') return false;
        if (observation.madrs <= 0 && observation.madrs >= 60) return false;
        if (observation.timestamp <= new Date(2000, 0, 0)) return false;
        if (observation.timestamp > currentDate) {
            toast({
                position: 'bottom',
                description: 'Tidspunkt kan ikke være frem i tid',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            return false;
        }
        return true;
    }
    const navigate = useNavigate();

    const [observationText, setObservationText] = React.useState('');
    const [observationTime, setObservationTime] = React.useState('');
    const [observationDate, setObservationDate] = React.useState('');
    const [observationMdrs, setobservationMdrs] = React.useState(0);
    const [state, setState] = React.useState<State>(initialCheckboxState);

    const [observationReportedBy, setobservationReportedBy] =
        React.useState('');

    const names = [
        'forvirret',
        'irritert',
        'brautendeAdferd',
        'fysiskeTrusler',
        'verbaleTrusler',
        'slagSparkMotInventar',
    ];

    const descriptions = [
        '(Mer) forvirret',
        '(Mer) irritabel',
        '(Mer) brautende adferd',
        '(Oftere) fysiske trusler',
        '(Oftere) verbale trusler',
        '(Oftere) slag, spark etc. mot inventar',
    ];

    const toggleCheckbox = (name: keyof State) => {
        setState((prevState) => ({
            ...prevState,
            [name]: prevState[name] === 0 ? 1 : 0,
        }));
    };

    const onSubmit = async () => {
        const {
            forvirret,
            irritert,
            brautendeAdferd,
            fysiskeTrusler,
            verbaleTrusler,
            slagSparkMotInventar,
        } = state;

        const observation = {
            roomId: roomId ? parseInt(roomId, 10) : -1,
            timestamp: new Date(`${observationDate}T${observationTime}:00`),
            description: observationText,
            reportedBy: observationReportedBy,
            madrs: observationMdrs,
            bvC0: forvirret,
            bvC1: irritert,
            bvC2: brautendeAdferd,
            bvC3: fysiskeTrusler,
            bvC4: verbaleTrusler,
            bvC5: slagSparkMotInventar,
        };

        if (isValidForm(observation)) {
            try {
                await SaveObservation(observation);
                navigate('/');
            } catch (error: unknown) {
                let message = 'En uventet feil oppstod';
                if (error instanceof Error) {
                    message = error.message;
                }

                toast({
                    position: 'bottom',
                    description: message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        }
    };

    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();
                onSubmit();
            }}
        >
            <Box bg='staySafe.lightMint' minH='calc(100vh - 130px)' pb='4rem'>
                <Box ml={{ base: 0, md: 24 }}>
                    <Button
                        text='Tilbake'
                        variant={ButtonVariant.text}
                        leftIcon={<FiArrowLeft size='24px' />}
                        onClickCallback={() => {
                            navigate('/');
                        }}
                    />
                </Box>

                <Container maxW='container.sm'>
                    <Box>
                        <Heading>
                            Registrer observasjon på{' '}
                            {oneRoom?.name?.toLowerCase()}
                        </Heading>
                    </Box>

                    <Box
                        mt={8}
                        backgroundColor='white'
                        padding='2'
                        borderRadius='2px'
                    >
                        <Heading size='md' mb={4}>
                            Aggresjon (BVC)
                        </Heading>
                        <Stack spacing={5} direction='column'>
                            {names.map((name, i) => (
                                <CheckboxComponent
                                    key={name}
                                    name={name}
                                    state={state}
                                    handleFunction={toggleCheckbox}
                                >
                                    {descriptions[i]}
                                </CheckboxComponent>
                            ))}
                        </Stack>
                    </Box>

                    <Box my={4}>
                        <Heading size='sm'>
                            Depresjon - MADRS Totalscore
                        </Heading>
                        <FormControl
                            isInvalid={
                                observationMdrs < 0 && observationMdrs > 60
                            }
                        >
                            <Input
                                value={observationMdrs}
                                onInput={(e) =>
                                    setobservationMdrs(
                                        (e.target as HTMLInputElement)
                                            .valueAsNumber
                                    )
                                }
                                type='number'
                                min={0}
                                max={60}
                                maxW='100px'
                                mt={2}
                            />
                        </FormControl>
                    </Box>

                    <Box my={8}>
                        <FormLabel fontWeight='bold'>Beskrivelse</FormLabel>
                        <FormControl isInvalid={observationText === ''}>
                            <Textarea
                                required
                                value={observationText}
                                onInput={(e) =>
                                    setObservationText(
                                        (e.target as HTMLTextAreaElement).value
                                    )
                                }
                                placeholder='Observasjoner'
                                height='155px'
                            />
                        </FormControl>
                    </Box>

                    <Box mb={8}>
                        <FormLabel fontWeight='bold'>
                            Tidspunkt for observasjon
                        </FormLabel>
                        <FormLabel>
                            Legg inn så nøyaktig tidspunkt for observasjon som
                            mulig, da input fra deg brukes til å kryss-sjekke
                            data fra monitorering.
                        </FormLabel>
                        <Grid templateColumns='126px 187px' columnGap='18px'>
                            <FormControl isInvalid={observationTime === ''}>
                                <Input
                                    required
                                    value={observationTime}
                                    onInput={(e) =>
                                        setObservationTime(
                                            (e.target as HTMLInputElement).value
                                        )
                                    }
                                    height='52px'
                                    type='time'
                                />
                            </FormControl>
                            <FormControl isInvalid={observationDate === ''}>
                                <Input
                                    required
                                    value={observationDate}
                                    onInput={(e) =>
                                        setObservationDate(
                                            (e.target as HTMLInputElement).value
                                        )
                                    }
                                    height='52px'
                                    type='date'
                                />
                            </FormControl>
                        </Grid>
                    </Box>

                    <Box my={4}>
                        <Heading size='sm'>Brukernavn</Heading>
                        <FormControl isInvalid={observationReportedBy === ''}>
                            <Input
                                required
                                value={observationReportedBy}
                                onInput={(e) =>
                                    setobservationReportedBy(
                                        (e.target as HTMLInputElement).value
                                    )
                                }
                                maxW='166px'
                                mt={2}
                            />
                        </FormControl>
                    </Box>

                    <Box>
                        <Button
                            text='Registrer observasjon'
                            variant={ButtonVariant.primary}
                            type={ButtonType.submit}
                        />
                    </Box>
                </Container>
            </Box>
        </form>
    );
};
