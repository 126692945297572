import * as React from 'react';
import { ArrowUpDownIcon } from '@chakra-ui/icons';
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Text,
    Grid,
} from '@chakra-ui/react';
import { BVCChart } from 'components/common/charts/BVCChart';
import { BVCTable } from 'components/common/tables/BVCTable';
import { TableExplanation } from 'components/common/utils/TableExplanation';

interface propType {
    roomId: string | undefined;

    startDate: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function BVCChartView(props: propType) {
    const { roomId, startDate } = props;
    return (
        <Grid>
            <BVCChart roomId={roomId} startDate={startDate} />
            <TableExplanation />
            <Accordion marginBottom='40px' allowToggle marginTop='64px'>
                <AccordionItem>
                    <AccordionButton color='staySafe.blue'>
                        <Text marginRight='12px'>Vis tabell</Text>
                        <ArrowUpDownIcon />
                    </AccordionButton>
                    <AccordionPanel>
                        <BVCTable roomId={roomId} startDate={startDate} />
                        <TableExplanation />
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Grid>
    );
}

export { BVCChartView };
