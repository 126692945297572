import axios, { AxiosResponse } from 'axios';
import { AlarmDto } from 'models/dtos/AlarmDto';
import { MeasurementRoomMap } from 'models/dtos/MeasurementRoomMap';
import { MeasurementValueResponseDto } from 'models/dtos/MeasurementValueResponseDto';
import { RoomDto } from 'models/dtos/RoomDto';
import { SleepDto } from 'models/dtos/SleepDto';
import {
    SleepStageDistributionResponseDto,
    SleepStageResponseDto,
} from 'models/dtos/SleepStageResponseDto';
import { env } from 'env';
import { MonitoringDto } from 'models/dtos/MonitoringDto';

async function getMeasurement(
    token: string,
    room?: RoomDto[]
): Promise<MeasurementRoomMap> {
    const options = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };
    return axios
        .post(`${env.REACT_APP_API_URL}/measurement/latest`, room, options)
        .then((response: AxiosResponse) => {
            return response.data || {};
        });
}

export const getRoomStatus = async (token: string): Promise<RoomDto[]> => {
    const params = { reshIdHospitalPsychDep: env.REACT_APP_WARD_ID };
    const options = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
        params,
    };
    const response = await fetch(`${env.REACT_APP_API_URL}/room`, options);

    if (!response.ok) throw new Error(await response.text());

    const rooms = await response.json();

    return rooms;
};

async function getOneRoom(token: string, roomId?: string): Promise<RoomDto> {
    return axios
        .get(`${env.REACT_APP_API_URL}/room/${roomId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response: AxiosResponse) => {
            return response.data;
        });
}

async function getMeasurementValues(
    token: string,
    type: string,
    startDate: string,
    resultCount: number,
    sensorIdBathRoom?: string,
    sensorIdMainRoom?: string
): Promise<MeasurementValueResponseDto[]> {
    const endDate = JSON.stringify(new Date(Date.now())).replace(/"/g, '');
    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    return axios
        .get(
            `${env.REACT_APP_API_URL}/measurement?sensorIdMainRoom=${sensorIdMainRoom}&sensorIdBathRoom=${sensorIdBathRoom}&valueType=${type}&start=${startDate}&end=${endDate}&resultCount=${resultCount}`,
            options
        )
        .then((response: AxiosResponse) => {
            return response.data;
        });
}

async function getSleepStageDuration(
    token: string,
    startDate: string,
    sensorIdMainRoom?: string,
    sensorIdBathRoom?: string,
    isDetailed?: boolean
): Promise<SleepStageResponseDto[]> {
    const endDate = JSON.stringify(new Date(Date.now())).replace(/"/g, '');
    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    return axios
        .get(
            `${env.REACT_APP_API_URL}/sleep/SleepStageDuration?sensorIdMainRoom=${sensorIdMainRoom}&sensorIdBathRoom=${sensorIdBathRoom}&start=${startDate}&end=${endDate}&detail=${isDetailed}`,
            options
        )
        .then((response: AxiosResponse) => {
            return response.data;
        });
}

async function getSleepDistribution(
    token: string,
    startDate: string,
    sensorIdMainRoom?: string,
    sensorIdBathRoom?: string
): Promise<SleepStageDistributionResponseDto[]> {
    const endDate = JSON.stringify(new Date(Date.now())).replace(/"/g, '');
    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    return axios
        .get(
            `${env.REACT_APP_API_URL}/sleep/SleepStageDistribution?sensorIdMainRoom=${sensorIdMainRoom}&sensorIdBathRoom=${sensorIdBathRoom}&start=${startDate}&end=${endDate}`,
            options
        )
        .then((response: AxiosResponse) => {
            return response.data;
        });
}

async function getSleepOverview(
    token: string,
    startDate: string,
    resultCount: number,
    sensorIdMainRoom?: string,
    sensorIdBathRoom?: string
): Promise<SleepStageResponseDto[]> {
    const endDate = JSON.stringify(new Date(Date.now())).replace(/"/g, '');
    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    return axios
        .get(
            `${env.REACT_APP_API_URL}/sleep/SleepOverview?sensorIdMainRoom=${sensorIdMainRoom}&sensorIdBathRoom=${sensorIdBathRoom}&start=${startDate}&end=${endDate}&resultCount=${resultCount}
            `,
            options
        )
        .then((response: AxiosResponse) => {
            return response.data;
        });
}

async function getSleepReport(
    token: string,
    startDate: string,
    sensorIdMainRoom?: string,
    sensorIdBathRoom?: string
): Promise<SleepDto> {
    const endDate = JSON.stringify(new Date(Date.now())).replace(/"/g, '');
    return axios
        .get(
            `${env.REACT_APP_API_URL}/sleep/SleepReport?sensorIdMainRoom=${sensorIdMainRoom}&sensorIdBathRoom=${sensorIdBathRoom}&start=${startDate}&end=${endDate}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        .then((response: AxiosResponse) => {
            return response.data;
        });
}

async function getAlarm(token: string): Promise<AlarmDto[]> {
    return axios
        .get(`${env.REACT_APP_API_URL}/alarm`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response: AxiosResponse) => {
            return response.data;
        });
}

async function cancelAlarm(
    token: string,
    alarmId: string,
    userId: string
): Promise<AlarmDto> {
    const queryParams = `?alarmid=${alarmId}&userid=${userId}`;
    const response = await axios.put(
        `${env.REACT_APP_API_URL}/alarm/cancelalarm${queryParams}`,
        null,
        {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }
    );

    return response.data;
}

async function saveUpdateMonitoring(
    token: string,
    monitoring: MonitoringDto
): Promise<MonitoringDto> {
    const response = await axios.post(
        `${env.REACT_APP_API_URL}/room/monitoring
        `,
        monitoring,
        {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }
    );

    return response.data;
}

export {
    getMeasurement,
    getMeasurementValues,
    getOneRoom,
    getSleepStageDuration,
    getSleepDistribution,
    getSleepOverview,
    getSleepReport,
    getAlarm,
    cancelAlarm,
    saveUpdateMonitoring,
};
