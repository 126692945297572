// AuthErrorPage.tsx
import React from 'react';
import { Box, Button, Heading, Text, Center } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const AuthErrorPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Center minH='100vh' bg='red.50'>
            <Box textAlign='center'>
                <Heading as='h1' size='xl' color='red.500' mb='4'>
                    Authentication Configuration Error
                </Heading>
                <Text fontSize='lg' mb='6'>
                    There was an issue with the OIDC configuration. Please check
                    your setup and try again.
                </Text>
                <Button colorScheme='teal' onClick={() => navigate('/')}>
                    Go to Home
                </Button>
            </Box>
        </Center>
    );
};

export default AuthErrorPage;
